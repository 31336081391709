import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { useRoles } from 'utils/hooks/useRoles'
import { blocks, permissions } from 'configs/policies'
import roles from 'configs/roles'

type Role = (typeof roles)[keyof typeof roles]

interface Permission {
  allow: string[]
  block?: string[]
}

export function usePermission() {
  const { userHasOneOfRoles } = useRoles()
  const router = useRouter()

  const basePath = `/${router.pathname.split('/')[1]}`

  const currentPathPermissions = useCallback((): Permission => {
    // Route Path defined on policy
    if (permissions?.[router.pathname]) {
      return {
        allow:
          permissions?.[router.pathname]?.roles ??
          permissions?.[router.pathname] ??
          [],
        block:
          blocks?.[router.pathname]?.roles ?? blocks?.[router.pathname] ?? [],
      }
    }

    // Base Path defined on policy
    if (permissions?.[basePath]) {
      return {
        allow: permissions?.[basePath]?.roles ?? permissions?.[basePath] ?? [],
        block: blocks?.[basePath]?.roles ?? blocks?.[basePath] ?? [],
      }
    }

    return {
      allow: [],
      block: [],
    }
  }, [basePath, router])

  const checkPermissions = useCallback(
    (permissions: Permission = { allow: [], block: [] }): boolean => {
      if (permissions.allow?.length === 0) {
        return true
      } else {
        const hasValidRoles = userHasOneOfRoles(Object.values(roles) as Role[])
        const hasModulePermissions = userHasOneOfRoles(
          permissions.allow as Role[]
        )
        const blocked = userHasOneOfRoles(permissions.block ?? [])

        return hasValidRoles && hasModulePermissions && !blocked
      }
    },
    [userHasOneOfRoles]
  )

  return {
    checkPermissions,
    currentPathPermissions,
  }
}

import React from 'react'

import { useAuth } from 'utils/hooks/useAuth'
import availableRoles, {
  BCKS_ADMIN,
  BCKS_GTK,
  BCKS_REGION_PREFIX,
  BCKS_UPT_PREFIX,
  KEMENDAGRI,
} from 'configs/roles'

type Role = (typeof availableRoles)[keyof typeof availableRoles]

export const useRoles = () => {
  const { bcksUserSession } = useAuth()
  const currentRoles: string[] = React.useMemo(() => {
    return bcksUserSession?.user?.groups.map((group) => group.name) ?? []
  }, [bcksUserSession])

  const userHasRole = React.useCallback(
    (role: Role): boolean =>
      currentRoles.some((currentRole) => currentRole.startsWith(role)),
    [currentRoles]
  )

  const userHasOneOfRoles = React.useCallback(
    (roles: Role[]) =>
      roles.some((role) =>
        currentRoles.some((currentRole) => currentRole.startsWith(role))
      ),
    [currentRoles]
  )

  const isAdminUser = userHasRole(BCKS_ADMIN)
  const isGtkUser = userHasRole(BCKS_GTK)
  const isDinasUser = userHasRole(BCKS_REGION_PREFIX)
  const isAdminUPTUser = userHasRole(BCKS_UPT_PREFIX)
  const isKemendagriUser = userHasRole(KEMENDAGRI)

  return {
    userHasRole,
    userHasOneOfRoles,
    userRoles: currentRoles,
    isAdminUser,
    isGtkUser,
    isDinasUser,
    isAdminUPTUser,
    isKemendagriUser,
  }
}

// for some reason, using error for throwing error will always
// result in uncaught error, thus we don't extend Error class here

import { AxiosError } from 'axios'

export class CustomError {
  readonly title: string
  readonly description: string
  readonly apiPath: string
  readonly originalError: AxiosError

  constructor({
    title,
    description,
    apiPath,
    originalError,
  }: {
    title: string
    description: string
    apiPath: string
    originalError: AxiosError
  }) {
    this.title = title
    this.description = description
    this.apiPath = apiPath
    this.originalError = originalError
  }
}

export class SessionError extends CustomError {
  constructor(apiPath: string, originalError: AxiosError) {
    const title = 'Anda butuh login kembali'
    const description =
      'Klik tombol login di bawah ini untuk melanjutkan proses'
    super({ title, description, apiPath, originalError })
  }
}

export class InternalServerError extends CustomError {
  constructor(apiPath: string, originalError: AxiosError) {
    const title = 'Data gagal dimuat'
    const description =
      'Ada kesalahan pada sistem. Silakan coba beberapa saat lagi.'
    super({ title, description, apiPath, originalError })
  }
}

export class ForbiddenError extends CustomError {
  constructor(apiPath: string, originalError: AxiosError) {
    const title = 'Tidak dapat akses'
    const description =
      'Akun Anda tidak dapat digunakan untuk mengakses halaman ini. Silakan login menggunakan akun lain.'
    super({ title, description, apiPath, originalError })
  }
}

export class NotFoundError extends CustomError {
  constructor(apiPath: string, originalError: AxiosError) {
    const title = 'Data tidak ditemukan'
    const description = 'Data tidak ditemukan dalam sistem kami.'
    super({ title, description, apiPath, originalError })
  }
}

export class ServiceUnavailableError extends CustomError {
  constructor(apiPath: string, originalError: AxiosError) {
    const title = 'Server sedang tidak dapat diakses'
    const description =
      'Kami sedang memperbaiki sistem ini. Mohon maaf atas ketidaknyamanannya.'
    super({ title, description, apiPath, originalError })
  }
}

import { BCKS_UI_STATE_STORAGE_KEY } from 'configs/auth'

import { useLocalStorage } from './useLocalStorage'

export type UIStateId = 'homePanelCollapsed' | 'verificationPanelCollapsed'

type UIState = {
  [key in UIStateId]?: boolean
}

export const initialUIstate: UIState = {
  homePanelCollapsed: false,
  verificationPanelCollapsed: false,
}

export const useUIState = (): {
  data: UIState
  setUIState: (state: UIState) => void
  removeUIState: () => void
} => {
  const { data, set, remove } = useLocalStorage<UIState>(
    BCKS_UI_STATE_STORAGE_KEY,
    initialUIstate
  )

  return {
    data,
    setUIState: (state: UIState) => set({ ...data, ...state }),
    removeUIState: remove,
  }
}

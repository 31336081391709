import { RegionType } from 'types/region-type'

export type Jenjang = 'TK' | 'SD' | 'SMP' | 'SMA' | 'SMK' | 'SLB'

export const regionIdDKIJakarta = '010000'
export const regionIdsProvPapua = [
  '250000',
  '320000',
  '360000',
  '370000',
  '380000',
  '390000',
]
export const regionPrefixesProvPapua = regionIdsProvPapua.map((id) =>
  id.slice(0, 2)
)

export const listJenjangDefaultKabupaten: Jenjang[] = ['TK', 'SD', 'SMP']
export const listJenjangDefaultProvinsi: Jenjang[] = ['SMA', 'SMK', 'SLB']
export const listJenjangDKIJakarta: Jenjang[] = [
  'TK',
  'SD',
  'SMP',
  'SMA',
  'SMK',
  'SLB',
]
export const listJenjangPapuaProvinsi: Jenjang[] = ['SLB']
export const listJenjangPapuaKabupaten: Jenjang[] = [
  'TK',
  'SD',
  'SMP',
  'SMA',
  'SMK',
]

// RegionID is 6 letters long, can be used to identify province, kota/kabupaten, and kecamatan
// Example: 010203 -> 01: Province ID, 02: Kota/Kabupaten ID, 03: Kecamatan ID
// If last 4 digits are 0000, it's a province
// If last 2 digits are 00, it's a kota/kabupaten
export const getRegionType = (regionId: string): RegionType => {
  if (
    regionId.length < 6 ||
    regionId.split('').filter((char) => !isNaN(Number(char))).length < 6
  ) {
    return RegionType.DEFAULT
  }

  if (regionId.slice(-4) === '0000') {
    return RegionType.PROVINSI
  }

  if (regionId.slice(-2) === '00') {
    return RegionType.KABUPATEN
  }

  return RegionType.KECAMATAN
}

export const isRegionIdKecamatan = (regionId: string): boolean =>
  getRegionType(regionId) === RegionType.KECAMATAN

export const isRegionIdKabupaten = (regionId: string): boolean =>
  getRegionType(regionId) === RegionType.KABUPATEN

export const isRegionIdProvinsi = (regionId: string): boolean =>
  getRegionType(regionId) === RegionType.PROVINSI

export const getListJenjangByRegionType = (regionType: RegionType) => {
  if (regionType === RegionType.KABUPATEN) {
    return listJenjangDefaultKabupaten
  }

  if (regionType === RegionType.PROVINSI) {
    return listJenjangDefaultProvinsi
  }

  return []
}

export const getListJenjangSpecialCase = (regionId: string): Jenjang[] => {
  if (regionId === regionIdDKIJakarta) {
    return listJenjangDKIJakarta
  }

  if (regionIdsProvPapua.includes(regionId)) {
    return listJenjangPapuaProvinsi
  }

  if (regionPrefixesProvPapua.some((prefix) => regionId.startsWith(prefix))) {
    return listJenjangPapuaKabupaten
  }

  return []
}

export const getListJenjang = (
  regionId: string,
  regionType: RegionType
): Jenjang[] => {
  return regionId === regionIdDKIJakarta ||
    regionPrefixesProvPapua.some((prefix) => regionId.startsWith(prefix))
    ? getListJenjangSpecialCase(regionId)
    : getListJenjangByRegionType(regionType)
}

import {
  useAPIGetRegionDetail,
  useAPIGetRegions,
} from 'api/region/useAPIRegion'
import React from 'react'
import { Session } from 'types/auth'
import { RegionType } from 'types/region-type'

import { BCKS_SESSION_STORAGE_KEY } from 'configs/auth'
import { BCKS_REGION_PREFIX, BCKS_UPT_PREFIX } from 'configs/roles'

import { useLocalStorage } from '../useLocalStorage'
import { useRoles } from '../useRoles'

const DINAS_PARSED_ROLE_PREFIX = `DINAS`

export function useRegionHook() {
  const { data: localStorageSession, set: setLocalStorageSession } =
    useLocalStorage<Session>(BCKS_SESSION_STORAGE_KEY, null)
  const { userRegion } = localStorageSession
  const { userRoles } = useRoles()
  const { status, data } = useAPIGetRegions()
  const { data: regionDetail, isLoading: isLoadingRegionDetail } =
    useAPIGetRegionDetail(userRegion?.id, {
      type: userRegion?.type,
    })

  const setSelectedRegion = (selectedRegion) => {
    setLocalStorageSession({
      ...localStorageSession,
      userRegion: selectedRegion,
    })
  }

  const userProvinceIds = React.useMemo(
    () =>
      userRoles
        .filter((role) => role.startsWith(BCKS_UPT_PREFIX))
        .map((role) => role.split('_').pop()),
    [userRoles]
  )

  const userScopedRegionData = React.useMemo(
    () =>
      data?.regions.filter((region) =>
        userProvinceIds.length
          ? userProvinceIds.includes(region.province.id)
          : true
      ) ?? [],
    [data, userProvinceIds]
  )

  const getUserScopedFlattenProvinceData = React.useMemo(
    () =>
      userScopedRegionData.map((region) => ({
        id: region.province.id,
        name: region.province.name,
        type: RegionType.PROVINSI,
      })) ?? [],
    [userScopedRegionData]
  )

  const getUserScopedFlattenCityData = React.useMemo(
    () =>
      userScopedRegionData
        .map((region) =>
          region.cities.map((city) => ({
            id: city.id,
            name: city.name,
            type: RegionType.KABUPATEN,
          }))
        )
        .flat() ?? [],
    [userScopedRegionData]
  )

  const getFlattenProvinceData = React.useMemo(
    () =>
      data?.regions?.map((region) => ({
        id: region.province.id,
        name: region.province.name,
        type: RegionType.PROVINSI,
      })) ?? [],
    [data]
  )

  const getFlattenCityData = React.useMemo(
    () =>
      data?.regions
        ?.map((region) =>
          region.cities.map((city) => ({
            id: city.id,
            name: city.name,
            type: RegionType.KABUPATEN,
          }))
        )
        .flat() ?? [],
    [data]
  )

  const getCityDataByProvinceId = React.useCallback(
    (provinceId: string) => {
      return (
        data?.regions?.find((region) => region.province.id === provinceId)
          ?.cities ?? []
      )
    },
    [data]
  )

  const userScopedregionDataByIdAndNameOnly = React.useMemo(
    () => [
      ...getUserScopedFlattenProvinceData,
      ...getUserScopedFlattenCityData,
    ],
    [getUserScopedFlattenProvinceData, getUserScopedFlattenCityData]
  )

  const regionDataByIdAndNameOnly = React.useMemo(
    () => [...getFlattenProvinceData, ...getFlattenCityData],
    [getFlattenProvinceData, getFlattenCityData]
  )

  const parseGroupNameToRegionName = React.useCallback(
    (groupName: string) => {
      const region = userScopedregionDataByIdAndNameOnly.find((region) =>
        groupName.includes(region.id)
      )
      const parsedGroupName = region?.name ?? groupName
      return groupName.startsWith(BCKS_REGION_PREFIX)
        ? `${DINAS_PARSED_ROLE_PREFIX} ${region?.name}`
        : parsedGroupName
    },
    [userScopedregionDataByIdAndNameOnly]
  )

  // get province name by id
  const getProvinceNameById = React.useCallback(
    (provinceId: string) => {
      return (
        data?.regions?.find((region) => region.province.id === provinceId)
          ?.province.name ?? ''
      )
    },
    [data]
  )

  const getProvinceAsSelectOptions = React.useMemo(
    () =>
      data?.regions?.map((region) => ({
        value: region.province.id,
        label: region.province.name,
      })) ?? [{ value: null, label: null }],
    [data]
  )

  const getRegionNameByRegionId = React.useCallback(
    (regionId) => {
      return (
        regionDataByIdAndNameOnly.find((region) => region.id === regionId)
          ?.name ?? ''
      )
    },
    [regionDataByIdAndNameOnly]
  )

  return {
    regionFetchStatus: status,
    regionData: userScopedRegionData,
    provinceData: getUserScopedFlattenProvinceData,
    cityData: getUserScopedFlattenCityData,
    allRegionData: userScopedregionDataByIdAndNameOnly,
    getParsedGroupName: parseGroupNameToRegionName,
    getCityDataByProvinceId,
    getProvinceNameById,
    getRegionNameByRegionId,
    provinceSelectOptions: [
      { value: null, label: null },
      ...getProvinceAsSelectOptions,
    ],
    isLoadingRegionDetail,
    regionDetail: regionDetail || {
      id: userRegion?.id,
      type: userRegion?.type,
      name: '',
    },
    setSelectedRegion,
    resetSelectedRegion: () =>
      setSelectedRegion({
        id: '',
        type: '',
      }),
  }
}

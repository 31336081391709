import React from 'react'
import { RegionType } from 'types/region-type'

import { useRegionHook } from './useRegionHook'
export const RegionContextDefaultValue = {
  regionFetchStatus: null,
  regionData: null,
  provinceData: [],
  cityData: null,
  allRegionData: null,
  getParsedGroupName: (groupName: string) => groupName,
  // eslint-disable-next-line
  getCityDataByProvinceId: (selectedProvinceId: string) => null,
  getProvinceNameById: (provinceId: string) => provinceId,
  getRegionNameByRegionId: (regionId: string) => regionId,
  provinceSelectOptions: [],
  isLoadingRegionDetail: true,
  regionDetail: {
    id: '',
    name: '',
    type: RegionType.KABUPATEN,
  },
  setSelectedRegion: (region) => region,
  resetSelectedRegion: () => null,
}

export const RegionContext = React.createContext(RegionContextDefaultValue)

RegionContext.displayName = 'RegionContext'

export function useRegionContext() {
  const context = React.useContext(RegionContext)
  if (context === undefined) {
    throw new Error('useRegionContext must be used within a RegionProvider')
  }
  return context
}

export function WithRegionContext(props: any) {
  const regionHookData = useRegionHook()

  return <RegionContext.Provider value={regionHookData} {...props} />
}

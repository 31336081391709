import type { Session } from 'types/auth'

import { BCKS_SESSION_STORAGE_KEY } from 'configs/auth'

export function setAuthToAPI(url: string, { guruToken }: Session): string {
  const urlObj = new URL(url)
  urlObj.searchParams.set('guruToken', guruToken)
  return urlObj.toString()
}

export function getSession(
  storageKey: string = BCKS_SESSION_STORAGE_KEY
): Session | null {
  try {
    return JSON.parse(window.localStorage.getItem(storageKey))
  } catch {
    return null
  }
}

import FileSaver from 'file-saver'

const MSG_ERR_FILE_NOT_FOUND = 'File tidak ditemukan'

export const getFileNameFromUrl = (fileUrl: string): string =>
  new URL(fileUrl).pathname.split('/').slice(-1)[0]

export const handleDownloadFile = (file: Blob, fileName = '') => {
  const fileBlob = new Blob([file], { type: file.type })
  FileSaver.saveAs(fileBlob, fileName)
}

export const getFileFromUrl = async (
  fileUrl: string,
  fileName?: string
): Promise<File> => {
  const response = await fetch(fileUrl)
  if (response.status >= 400) throw new Error(MSG_ERR_FILE_NOT_FOUND)

  const blob = await response.blob()

  return new File([blob], (fileName || getFileNameFromUrl(fileUrl)) ?? fileUrl)
}

export const downloadFileFromUrl = async (
  fileUrl: string,
  fileName?: string
) => {
  const file = await getFileFromUrl(fileUrl, fileName)
  const usedFileName = fileName || getFileNameFromUrl(fileUrl)

  handleDownloadFile(file, usedFileName)
}

import {
  BCKS_UPT_PREFIX,
  ROLES_ALLOWED_ACCESS_DATA_GP,
  ROLES_ALLOWED_CHANGE_REGION,
  ROLES_ALLOWED_DEFAULT,
  ROLES_ALLOWED_GET_LAPORAN_KEMENDAGRI,
  ROLES_ALLOWED_LAPOR_KEMENDAGRI,
  ROLES_ALLOWED_USER_MANAGEMENT,
} from './roles'

export const permissions = {
  '/': ROLES_ALLOWED_DEFAULT,
  '/home': ROLES_ALLOWED_DEFAULT,
  '/region': ROLES_ALLOWED_CHANGE_REGION,
  '/data_gp': ROLES_ALLOWED_ACCESS_DATA_GP,
  '/access_management': ROLES_ALLOWED_USER_MANAGEMENT,

  // Lapor Kemendagri
  '/kemendagri': ROLES_ALLOWED_GET_LAPORAN_KEMENDAGRI,
  '/ks/mutations/[ptkId]/lapor-kemendagri': ROLES_ALLOWED_LAPOR_KEMENDAGRI,
  '/ks/history-selection/lapor-kemendagri/[selectionId]':
    ROLES_ALLOWED_LAPOR_KEMENDAGRI,
}

export const blocks = {
  '/kemendagri': [BCKS_UPT_PREFIX], // This is needed because Admin UPT Users has bcks_gtk role which is also in ROLES_ALLOWED_GET_LAPORAN_KEMENDAGRI
}

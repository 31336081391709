import { useTracker } from 'api/tracker/useAPITracker'
import Link from 'next/link'
import React from 'react'

import { Icon } from '@wartek-id/icon'
import { SideBarItem, VerticalNavigation } from '@wartek-id/vertical-navigation'

import { useRoles } from 'utils/hooks'

const isNewKemenpanrbFlowEnabled =
  process.env.NEXT_PUBLIC_TOGGLE_PS_SELECTION_NEW_KEMENPANRB_FLOW === 'true'

interface DesktopSidebarProps {
  activeKey?: string
  readonly children?: React.ReactNode
}

export const DesktopSidebar = ({ activeKey = '' }: DesktopSidebarProps) => {
  const { satuEventTrack } = useTracker()
  const { isAdminUser, isAdminUPTUser, isGtkUser, isKemendagriUser } =
    useRoles()

  const sidebarMenuList: SideBarItem[] = [
    ...(!isKemendagriUser
      ? [
          {
            icon: <Icon>home</Icon>,

            isActive: activeKey === 'home',
            label: 'Beranda',
            href: '/home',
          },
        ]
      : []),
    ...(isAdminUser || isKemendagriUser
      ? [
          {
            icon: <Icon>timeline</Icon>,
            isActive: activeKey === 'data_gp',
            label: 'Dasbor Data GP',
            href: '/data_gp',
          },
        ]
      : []),
    ...(!isKemendagriUser
      ? [
          {
            icon: <Icon>supervisor_account</Icon>,
            isActive: [
              'ks_demand',
              'ks_supply',
              'ks_active',
              'ks_pensiun',
              'ks_history_selection',
            ].includes(activeKey),
            label: 'Kepala Sekolah (KS)',
            href: '',
            children: [
              {
                isActive: activeKey === 'ks_demand',
                label: 'Daftar Kebutuhan KS',
                href: '/ks/demand',
                onClick() {
                  satuEventTrack({ event_name: 'view_demand_ks' })
                },
              },
              {
                isActive: activeKey === 'ks_supply',
                label: 'Daftar Ketersediaan BCKS',
                href: '/ks/supply',
                onClick() {
                  satuEventTrack({ event_name: 'view_supply_bcks' })
                },
              },
              {
                isActive: activeKey === 'ks_active',
                label: 'Daftar KS Aktif',
                href: '/ks/active',
              },
              {
                isActive: activeKey === 'ks_pensiun',
                label: 'Daftar KS Segera Pensiun',
                href: '/ks/pensiun',
              },
              {
                isActive: activeKey === 'ks_mutations',
                label: 'Mutasi KS',
                href: '/ks/mutations',
              },
              {
                isActive: activeKey === 'ks_history_selection',
                label: 'Riwayat Seleksi',
                href: '/ks/history-selection',
                onClick() {
                  satuEventTrack({
                    event_name: 'click_riwayat_seleksi_ks',
                  })
                },
              },
            ],
          },
        ]
      : []),
    ...(!isKemendagriUser
      ? [
          {
            icon: <Icon>supervisor_account</Icon>,
            isActive: ['ps_demand', 'ps_supply'].includes(activeKey),
            label: 'Pengawas Sekolah (PS)',
            href: '',
            children: [
              {
                isActive: activeKey === 'ps_demand',
                label: 'Kebutuhan PS',
                href: '/ps/demand',
                onClick() {
                  satuEventTrack({ event_name: 'view_demand_ps' })
                },
              },
              {
                isActive: activeKey === 'ps_supply',
                label: 'Daftar Ketersediaan BCPS',
                href: '/ps/supply',
                onClick() {
                  satuEventTrack({ event_name: 'view_supply_bcps' })
                },
              },
              ...(!isAdminUPTUser && (isAdminUser || isGtkUser)
                ? [
                    {
                      isActive: activeKey === 'ps_list_allocation_request',
                      label: 'Daftar Pengajuan Formasi',
                      href: '/ps/allocation-requests',
                    },
                  ]
                : []),
              ...(isNewKemenpanrbFlowEnabled &&
              !isAdminUPTUser &&
              (isAdminUser || isGtkUser)
                ? [
                    {
                      isActive:
                        activeKey === 'ps_list_formation_approval_request',
                      label: 'Daftar Persetujuan Formasi',
                      href: '/ps/formation-approval-requests',
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(!isKemendagriUser
      ? [
          {
            icon: <Icon>download</Icon>,
            isActive: false,
            label: 'Unduh Juknis',
            href: '',
            onClick() {
              satuEventTrack({ event_name: 'click_juknis_ks' })
              window.open(
                'https://drive.google.com/file/d/1FhZ1eXQUxKR1g27YgMQfrKGelLmI5B4D/view?usp=sharing',
                '_blank'
              )
            },
          },
          {
            icon: <Icon>help</Icon>,
            isActive: false,
            label: 'Bantuan',
            href: '',
            onClick() {
              satuEventTrack({ event_name: 'click_bantuan' })
              window.open('https://ksps.zendesk.com/hc', '_blank')
            },
          },
        ]
      : []),
    ...(isAdminUser || isAdminUPTUser
      ? [
          {
            icon: <Icon>supervised_user_circle</Icon>,
            isActive: activeKey === 'access_management',
            label: 'Manajemen Akses',
            href: '/access_management',
          },
        ]
      : []),
  ]

  return (
    <div className="fixed h-full w-[316px] border-r border-[#EDEDED] bg-[#103178] p-4 shadow-md transition-all">
      <VerticalNavigation inverse items={sidebarMenuList} customLink={Link} />
    </div>
  )
}

export const ADMIN = 'admin'
export const BCKS_ADMIN = 'bcks_admin'
export const BCKS_GTK = 'bcks_gtk'
export const BCKS_DINAS = 'bcks_dinas'
export const BCKS_REGION_PREFIX = 'bcks_region'
export const BCKS_UPT_PREFIX = 'bcks_adminprovinsi'

export const KEMENDAGRI = 'kemendagri'

export const ROLES_ALLOWED_LOGIN: string[] = [
  BCKS_ADMIN,
  BCKS_GTK,
  BCKS_REGION_PREFIX,
  BCKS_UPT_PREFIX,
  KEMENDAGRI,
]

export const ROLES_ALLOWED_DEFAULT: string[] = [
  BCKS_ADMIN,
  BCKS_GTK,
  BCKS_REGION_PREFIX,
  BCKS_UPT_PREFIX,
]

export const ROLES_ALLOWED_CHANGE_REGION: string[] = [BCKS_ADMIN, BCKS_GTK]

export const ROLES_ALLOWED_ACCESS_DATA_GP: string[] = [BCKS_ADMIN, KEMENDAGRI]

export const ROLES_ALLOWED_USER_MANAGEMENT: string[] = [
  BCKS_ADMIN,
  BCKS_UPT_PREFIX,
]

export const ROLES_ALLOWED_LAPOR_KEMENDAGRI: string[] = [BCKS_REGION_PREFIX]

export const ROLES_ALLOWED_GET_LAPORAN_KEMENDAGRI: string[] = [
  BCKS_ADMIN,
  BCKS_GTK,
  KEMENDAGRI,
]

const roles = {
  BCKS_GTK,
  BCKS_REGION_PREFIX,
  BCKS_UPT_PREFIX,
  BCKS_ADMIN,
  KEMENDAGRI,
}

export default roles

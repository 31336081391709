import React from 'react'
import type { AuthContextProps } from 'types/auth'

export const AuthContextDefaultValue: AuthContextProps = {
  bcksUserSession: null,
  userHasNoAccess: false,
  logout: () => ({}),
}

export const AuthContext = React.createContext<AuthContextProps>(
  AuthContextDefaultValue
)
AuthContext.displayName = 'AuthContext'

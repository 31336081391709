import { RegionType } from 'types/region-type'

import { useQueryApi } from 'utils/api'
import { usePermission } from 'utils/hooks'
import { baseAPIPathGuruKS } from 'configs/api'
import { ROLES_ALLOWED_CHANGE_REGION } from 'configs/roles'

const basePath = `${baseAPIPathGuruKS}/v1/regions`

export interface Province {
  id: string
  name: string
}

export interface City {
  id: string
  name: string
}

export interface Region {
  province: Province
  cities: City[]
}

export interface ParamsGetRegionDetail {
  type?: RegionType
}

export interface ResponseSuccessGetRegions {
  regions: Region[]
}

export interface ResponseSuccessGetRegionDetail {
  id: string
  name: string
  type: RegionType
}

export const useAPIGetRegions = () => {
  const { checkPermissions } = usePermission()

  return useQueryApi<ResponseSuccessGetRegions>(basePath, {
    queryConfig: {
      useErrorBoundary: false,
      enabled: checkPermissions({ allow: ROLES_ALLOWED_CHANGE_REGION }),
    },
  })
}

export const useAPIGetRegionDetail = (
  regionId: string,
  params: ParamsGetRegionDetail = {}
) =>
  useQueryApi<ResponseSuccessGetRegionDetail>(`${basePath}/${regionId}`, {
    axiosConfig: {
      params,
    },
    queryConfig: {
      enabled: !!regionId,
    },
  })
